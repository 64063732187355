import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

function Navbar() {

  return (
    <div className="navbar-container">
      <div className="nav-padding">
        <NavLink to="/" aria-current="page" className="" aria-label="home">
          <div className="logo-container">
            <div className="logo-image">
              <img src={process.env.PUBLIC_URL + '/assets/Misc/pfp.png'} loading="lazy" width="48" height="48" alt="Scott Tollerton"/>
            </div>
            <div className="logo-text">
              <div className="logo-name">Scott Tollerton</div>
              <div className="logo-title">Front-End and Interaction Designer</div>
            </div>
          </div>
        </NavLink>
        <nav role="navigation" className="link-container">
          <NavLink to="/work" className="nav-item">Home</NavLink>
          <NavLink to="/about" className="nav-item">About</NavLink>
          <a href="/assets/Scott_Tollerton_Resume.pdf" target="_blank" rel="noopener noreferrer">Resumé</a>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
